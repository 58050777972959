<template>
    <div class="masonry-grid" ref="grid">
        <slot />
    </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, PropType, ref, UnwrapRef, watch } from 'vue';

const props = defineProps({
    watch: {
        type: Array as PropType<Array<UnwrapRef<any>>>,
        required: false,
    },
});

const grid = ref<HTMLElement>();
const currentResizeObserverElements = ref<Array<any>>([]);

const gridResizeObserver = new ResizeObserver((items) => {
    items.forEach((item) => {
        resizeGridItem(item.target.parentElement);
    });
});

const allItems = () => {
    return Array.from(grid.value?.children ?? []);
};

const computedStyle = computed(() => window.getComputedStyle(grid.value));

/**
 * The trick is setting up repeating grid rows that are fairly short, letting the elements fall into the
 * grid horizontally as they may, then adjusting their heights to match the grid with some fairly light math to
 * calculate how many rows they should span.
 *
 * @see https://css-tricks.com/piecing-together-approaches-for-a-css-masonry-layout/
 * @param item
 */
function resizeGridItem(item: HTMLElement) {
    const gridRatio = parseInt(computedStyle.value.getPropertyValue('grid-auto-rows'));
    const rowGap = parseInt(computedStyle.value.getPropertyValue('--items-row-gap'));

    const innerItem = item.firstElementChild;
    const itemHeight = innerItem.getBoundingClientRect().height;

    const rowSpan = itemHeight + rowGap - gridRatio;

    item.style.gridRowEnd = 'span ' + Math.ceil(rowSpan / gridRatio);
}

function resizeAllGridItems() {
    allItems().forEach((item: any) => resizeGridItem(item));
}

function applyResizeObserver() {
    // Unobserves any existing elements and then re-applies the elements to be observed
    currentResizeObserverElements.value.forEach((item) => gridResizeObserver.unobserve(item));
    currentResizeObserverElements.value.splice(0, currentResizeObserverElements.value.length);

    const items = allItems().map((item) => item.firstElementChild);
    currentResizeObserverElements.value.push(...items);

    items.forEach((item) => {
        gridResizeObserver.observe(item, { box: 'content-box' });
    });
}

watch(
    props.watch,
    () => {
        // Wait for the elements to be rendered, then apply the observer
        nextTick(() => {
            applyResizeObserver();
            resizeAllGridItems();
        });
    },
    {
        immediate: true,
    }
);
</script>

<style lang="scss" scoped>
.masonry-grid {
  // -- CUSTOMIZABLE VARIABLES -- //
  --items-per-row: 2;
  --items-column-gap: 40px;
  --items-row-gap: 60px;
  // ^^ CUSTOMIZABLE VARIABLES ^^ //

  display: grid;
  grid-template-columns: repeat(var(--items-per-row), minmax(200px, 1fr));
  grid-row-gap: 0; // Must be set to 0
  grid-auto-rows: 1px; // Should be set to 1px
  grid-column-gap: var(--items-column-gap);

  &::v-deep(> * > :first-child) {
    // Important to make the ResizeObserver work
    display: block;
  }
}

.masonry-grid {
  @include media-breakpoint-down(xl) {
    --items-column-gap: 30px;
    --items-row-gap: 40px;
  }

  @include media-breakpoint-down(md) {
    --items-per-row: 1;
  }
}
</style>