<template>
    <div class="v-pagination">
        <div class="pagination__wrapper">
            <button @click="emit('prev')" :disabled="!hasPrevPage" class="pagination__arrow arrow--left">
                <IconArrowLeft />
            </button>

            <template v-for="i in pages">
                <template v-if="Math.abs(i - currentPage) <= 1 || i === lastPage || i === 0">
                    <button @click="emit('to', i)" class="pagination__number" :class="{ active: i === currentPage }" :disabled="i === currentPage">
                        {{ i + 1 }}
                    </button>
                </template>
                <template v-else-if="i === 1 || i === lastPage - 1">
                    <span class="pagination__number">•••</span>
                </template>
            </template>

            <button @click="emit('next')" :disabled="!hasNextPage" class="pagination__arrow arrow--right">
                <IconArrowRight />
            </button>
        </div>
    </div>
</template>
<script setup lang="ts">
import IconArrowLeft from './IconArrowLeft.vue';
import IconArrowRight from './IconArrowRight.vue';
import { PropType } from 'vue';

const emit = defineEmits(['prev', 'next', 'to']);

const props = defineProps({
    currentPage: {
        type: Number,
        required: true,
    },
    lastPage: {
        type: Number,
        required: true,
    },
    hasPrevPage: {
        type: Boolean,
        required: true,
    },
    hasNextPage: {
        type: Boolean,
        required: true,
    },
    pages: {
        type: Array as PropType<Array<number>>,
        required: true,
    },
});
</script>

<style lang="scss" scoped>
.v-pagination {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    @include media-breakpoint-down(xl) {
        margin-top: 80px;
    }
    @include media-breakpoint-down(md) {
        margin-top: 50px;
    }

    .pagination__wrapper {
        display: flex;
        gap: 35px;
        align-items: center;
        @include media-breakpoint-down(xl) {
            gap: 30px;
        }
        @include media-breakpoint-down(md) {
            gap: 25px;
        }

        button {
            background: none;
            border: 0;
            transition: color $transition-duration $transition-curve;

            &.pagination__arrow {
                color: $primary;
                transition: transform $transition-duration $transition-curve;

                svg {
                    @include media-breakpoint-down(md) {
                        width: 39px;
                        height: 16px;
                    }
                }
                &:disabled {
                    color: $gray-200;
                }
                &.arrow--left {
                    padding-right: 15px;
                    @include media-breakpoint-down(md) {
                        padding-right: 5px;
                    }

                    &:hover {
                        transform: translate(-10px, 0);
                    }
                }
                &.arrow--right {
                    padding-left: 15px;
                    @include media-breakpoint-down(md) {
                        padding-left: 5px;
                    }

                    &:hover {
                        transform: translate(10px, 0);
                    }
                }
            }
            &.pagination__number {
                font-weight: bold;
                padding: 0;
                color: $body-color;
                @include media-breakpoint-down(md) {
                    font-size: 16px;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 14px;
                }

                &:hover,
                &.active {
                    color: $primary;
                }
            }
        }
    }
}
</style>
