import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { registerComponents } from './components/index.js';
import mitt from 'mitt';
import { Settings } from 'luxon';
import { makeI18nConfig } from './i18n.js';
import PrimeVue from 'primevue/config';

export default (el, replace = false) => {
    const i18nConfig = makeI18nConfig();
    const i18n = createI18n(i18nConfig);
    Settings.defaultLocale = i18nConfig.locale?.toLowerCase().replace('_', '-') || 'de-ch';

    const app = createApp({
        //
    });

    app.config.globalProperties.ellipsis = (value, length) => {
        return value.length > length ? `${value.substring(0, length)}...` : value;
    };
    app.config.globalProperties.i18n = i18nConfig;
    app.config.globalProperties.$wp = window.wp_data || {};
    app.config.globalProperties.$bus = app.config.globalProperties.$bus || mitt();
    app.use(i18n);
    app.use(PrimeVue, { ripple: true });

    registerComponents(app);

    if (replace) {
        app.mount(el).$nextTick(() => {
            const nodes = Array.from(el.childNodes);

            const root = el.parentNode;
            el.replaceWith(nodes.shift());

            nodes.forEach((node) => {
                root.appendChild(node);
            });
        });
    } else {
        app.mount(el);
    }

    return app;
};
