<template>
    <MultiSelect
        v-for="taxonomyOption in taxonomyOptions"
        v-model="selectedTerms[taxonomyOption.slug]"
        :options="taxonomyOption.options"
        optionLabel="label"
        optionValue="id"
        optionDisabled="disabled"
        :loading="loading"
        :disabled="loading"
        :placeholder="taxonomyOption.label"
        :autoOptionFocus="false"
        :showToggleAll="false"
        @change="onSelectTerm(taxonomyOption.slug, $event.value)"
    />
</template>

<script lang="ts" setup>
import { computed, onMounted, PropType, ref } from 'vue';
import MultiSelect from 'primevue/multiselect';
import { Paginator } from '@/js/vue/composition/paginator';

const props = defineProps({
    paginator: {
        type: Object as PropType<Paginator>,
        required: true,
    },
});

const { getSelectedTermIdsByTaxonomy, resetFilter, setTaxonomyFilter } = props.paginator;
const taxonomies = props.paginator.flatTaxonomies;
const { loading } = props.paginator;

const selectedTerms = ref([]);
const taxonomyOptions = ref([]);

const onSelectTerm = (slug, value) => {
    const previouslySelectedTermIds = getSelectedTermIdsByTaxonomy(slug);

    if (previouslySelectedTermIds.length < value.length) {
        // Is item has been added
        value.forEach((selectedTermId) => {
            if (previouslySelectedTermIds.includes(selectedTermId)) {
                return;
            }

            setTaxonomyFilter(slug, selectedTermId);
        });
    } else {
        // Is item has been removed
        previouslySelectedTermIds.forEach((previouslySelectedTermId) => {
            if (value.includes(previouslySelectedTermId)) {
                return;
            }

            setTaxonomyFilter(slug, previouslySelectedTermId);
        });
    }
};

onMounted(() => {
    taxonomies.value.forEach((taxonomy) => {
        taxonomyOptions.value.push({
            label: taxonomy.label,
            slug: taxonomy.slug,
            options: taxonomy.terms.map((term) => {
                const count = props.paginator.getCountForTerm(taxonomy.slug, term.slug);

                return {
                    id: term.term_id,
                    // label: computed(() => (props.paginator.requestTermCounts && count.value >= 0 ? `${term.name} (${count.value})` : term.name)),
                    label: term.name,
                    disabled: computed(() => (props.paginator.requestTermCounts ? count.value === 0 : false)),
                };
            }),
        });

        selectedTerms.value[taxonomy.slug] = getSelectedTermIdsByTaxonomy(taxonomy.slug);
    });
});
</script>

<style lang="scss" scoped>

</style>