<template>
    <transition name="modal">
        <div class="modal fullscreen" tabindex="-1" role="dialog" v-if="showModal" @keydown.esc="hide">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header filled">
                        <div :class="$wp.container">
                            <Cross class="float-right" @click="hide()"></Cross>
                            <span class="modal-title h1">
                                {{ $t('search.title') }}
                            </span>
                            <div class="row">
                                <div :class="$wp.columns" class="d-flex justify-content-between">
                                    <form class="form-inline mt-2 mt-md-0 w-100" @submit.prevent="doSearchDebounced">
                                        <div class="input-group input-group-transparent flex-grow-1 align-items-center">
                                            <input ref="input" type="text" v-model="query" @input="doSearchDebounced" class="form-control" :placeholder="$t('search.placeholder')" />
                                            <div class="input-group-append">
                                                <SearchIcon @click="doSearchDebounced"></SearchIcon>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-body">
                        <div :class="$wp.container">
                            <div class="row">
                                <div :class="$wp.columns">
                                    <div v-if="results || deposits" class="h4">{{ $t('search.results') }}</div>

                                    <div v-if="isSearching" class="spinner">
                                        <Spinner></Spinner>
                                    </div>

                                    <div v-else-if="(results?.length ?? 0 + deposits?.length ?? 0) > 0">
                                        <div class="deposit-results et_pb_wly_deposits">
                                            <div class="deposit--item" v-for="post of deposits">
                                                <a
                                                    v-if="post.zenodo_meta.files"
                                                    :href="post.zenodo_meta.files[0]?.links.content || null"
                                                    class="cover"
                                                    :style="{ background: post.colors.color_primary }"
                                                >
                                                    <div class="cover-content">
                                                        <div class="title" v-text="post.title"></div>

                                                        <div class="preview">
                                                            <img :src="post.featured_image" />
                                                        </div>
                                                    </div>
                                                </a>
                                                <div v-else class="cover" :style="{ background: post.colors.color_primary }">
                                                    <div class="cover-content">
                                                        <div class="title" v-text="post.title"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="results children-gutter-y">
                                            <div class="result" v-for="result of results">
                                                <a :href="result.url">
                                                    <span>{{ result.title }}</span>
                                                </a>
                                                <span v-html="ellipsis(result.excerpt, 150)"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-else-if="results && results.length === 0">
                                        <span>{{ $t('search.no_results') }}</span>
                                    </div>

                                    <div v-else-if="error">
                                        {{ error }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { api } from '../../api';
import Cross from '@/js/vue/components/Cross.vue';
import SearchIcon from '@/js/vue/components/SearchIcon.vue';
import Spinner from '@/js/vue/components/Spinner.vue';
import {debounce} from "@/js/libraries/debounce.js";

export default {
    components: { Spinner, SearchIcon, Cross },
    data: () => ({
        showModal: false,
        isSearching: false,
        results: null,
        deposits: null,
        error: null,
        query: null,
    }),
    mounted() {
        document.addEventListener('showSearch', this.show);

        this.doSearchDebounced = debounce(() => {
            this.doSearch();
        }, 300);
    },
    methods: {
        show() {
            this.showModal = true;
            document.body.classList.add('modal-open');
            this.$nextTick(() => this.$refs.input.focus());
        },
        hide() {
            this.showModal = false;
            document.body.classList.remove('modal-open');
        },
        doSearch() {
            this.isSearching = true;
            this.results = null;

            Promise.all([
                api.search({
                    search: this.query,
                    post_type: ['page', 'post', 'project', 'event'].join(','),
                    per_page: 15,
                }),
                api.posts('deposits', {
                    per_page: 3,
                    order: 'desc',
                    orderby: 'date',
                    search: this.query,
                }),
            ])
                .then(([res, deposits]) => {
                    this.results = res.data;
                    this.deposits = deposits.data;
                })
                .catch((err) => {
                    this.results = null;
                    this.error = (err && err.data.message) || null;
                })
                .then(() => {
                    this.isSearching = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.modal {
    .modal-header {
        padding-bottom: 50px !important;
    }

    .modal-title {
        margin-bottom: 100px !important;

        @include media-breakpoint-down(lg) {
            margin-bottom: 70px !important;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 50px !important;
        }
    }

    .modal-body {
        padding-top: 50px !important;
    }

    .spinner {
        width: 100%;
        text-align: center;
    }

    .result {
        display: flex;
        flex-direction: column;
    }

    .result > a {
        text-decoration: underline;

        &:hover {
            color: $primary;
        }
    }

    wly-icon-search::v-deep {
        margin-top: -5px;
    }

    .deposit-results {
        display: grid;
        gap: 16px;
        margin-bottom: 40px;

        @include media-breakpoints-down(
            grid-template-columns,
            1fr 1fr 1fr,
            (
                md: 1fr 1fr,
                sm: 1fr,
            )
        );

        .deposit--item {
            padding: 0;
            border: none;
            margin: 0 auto;
            max-width: 400px;

            .title {
                font-size: 20px;
            }
        }
    }
}
</style>
