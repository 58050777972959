<template>
    <div class="posts-loader" ref="root">
        <PostsFilter :paginator="paginator" v-if="showFilter"></PostsFilter>

        <div class="posts__wrapper">
            <div class="container">
                <template v-if="paginator.posts.value?.length > 0">
                    <MasonryGrid v-if="masonry" :watch="[paginator.posts.value]" class="post__overview">
                        <div v-for="post in paginator.posts.value" :key="post.id" v-html="post.content" class="post__item"></div>
                    </MasonryGrid>
                    <div v-else class="post__overview" :class="{ 'swiper-wrapper': showSlider }">
                        <div v-for="post in paginator.posts.value" :key="post.id" v-html="post.content" class="post__item"></div>
                    </div>
                </template>
                <div v-else-if="paginator.loading.value">
                    {{ $t('loading') }}
                </div>
                <div v-else-if="paginator.currentPage.value < 0">
                    <h4>{{ $t('no_posts') }}</h4>
                </div>

                <Pagination
                    v-if="showPagination"
                    :pages="paginator.pages.value"
                    :has-next-page="paginator.hasNextPage.value"
                    :has-prev-page="paginator.hasPrevPage.value"
                    :last-page="paginator.lastPage.value"
                    :current-page="paginator.currentPage.value"
                    @prev="paginator.toPrevPage"
                    @next="paginator.toNextPage"
                    @to="paginator.toPage"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue';
import { DiviTaxonomyDefinition, usePaginator } from '@/js/vue/composition/paginator';
import PostsFilter from '@/js/vue/components/Filter.vue';
import MasonryGrid from '@/js/vue/components/MasonryGrid.vue';
import Pagination from '@/js/vue/components/Pagination.vue';

const props = defineProps({
    taxonomies: {
        type: Array as PropType<Array<DiviTaxonomyDefinition | Array<DiviTaxonomyDefinition>>>,
        required: true,
    },
    paginatorOptions: {
        type: Object,
        required: true,
    },
    masonry: {
        type: Boolean,
        default: () => false,
    },
    showFilter: {
        type: Boolean,
        default: () => false,
    },
    showPagination: {
        type: Boolean,
        default: () => false,
    },
});

const root = ref<HTMLElement>();

const paginator = usePaginator({
    ...(props.paginatorOptions as any),
    taxonomies: props.taxonomies,
    url: props.paginatorOptions.url,
    scrollToElementOnFilterRestore: root,
});
</script>
