import PostsLoader from './Loaders/PostsLoader.vue';
import Deposits from './Deposits.vue';
import EventsAndProjectsGrid from './EventsAndProjectsGrid.vue';
import Cross from '@/js/vue/components/Cross.vue';
import Spinner from '@/js/vue/components/Spinner.vue';
import Search from '@/js/vue/components/Search.vue';
import CleverReach from '@/js/vue/components/CleverReach.vue';
import SearchIcon from '@/js/vue/components/SearchIcon.vue';
import { defineAsyncComponent } from 'vue';

export const registerComponents = (app) => {
    app.component(
        'wly-youtube',
        defineAsyncComponent(() => import('./YoutubePlayer.vue'))
    );
    app.component(
        'wly-more-content',
        defineAsyncComponent(() => import('./MoreContent.vue'))
    );

    app.component('wly-events-projects-grid', EventsAndProjectsGrid);
    app.component('posts-loader', PostsLoader);
    app.component('wly-deposits', Deposits);
    app.component('wly-cross', Cross);
    app.component('wly-spinner', Spinner);
    app.component('wly-icon-search', SearchIcon);
    app.component('wly-search', Search);
    app.component('clever-reach', CleverReach);
};
